import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { getComposedPath } from '../../functions/elements';
import { MenuComponent } from './menu.component';


@Directive({
  selector: '[hoverMenuTrigger]'
})
export class HoverMenuTriggerDirective implements OnInit, OnDestroy {

  private host: HTMLElement;
  private _menu: MenuComponent;

  @Input()
  set hoverMenuTrigger(value: MenuComponent) {
    this._menu = value;
  }

  get hoverMenuTrigger(): MenuComponent {
    return this._menu;
  }

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  hoverMenuBuffer: number = 20;

  private get menu(): MenuComponent {
    return this._menu;
  }

  constructor(private readonly elementRef: ElementRef, private readonly windowRef: WindowRef) {}

  ngOnInit() {
    this.host = this.elementRef.nativeElement as HTMLElement;
    this.host?.addEventListener('mouseenter', this.openingHandler);
    this.host?.addEventListener('keydown', this.keydownOpeningWrapperHandler);
  }

  ngOnDestroy() {
    this.host?.removeEventListener('mouseenter', this.openingHandler);
    this.host?.removeEventListener('keydown', this.keydownOpeningWrapperHandler);
  }

  // adding similar behavior for keyboard user only
  private keydownOpeningWrapperHandler = (e: KeyboardEvent) => {

    if (e.key === ' ' || e.key === 'Enter') {
      this.openingHandler(e);
    }

    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  private openingHandler = (_: UIEvent) => {

    if (!this.menu?.isOpen) {

      this.menu?.open();

      const menuElement = this.menu?.getHostElement();
      const hostElement = this.elementRef?.nativeElement as HTMLElement;

      const rect = hostElement.getBoundingClientRect();
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const buffer = this.hoverMenuBuffer ?? 20;
      const bufferRect = {
        x: rect.x - buffer,
        y: rect.y - buffer,
        width: rect.width + (buffer * 2),
        height: rect.height + (buffer * 2)
      };

      const closeFn = () => {
        this.windowRef.nativeWindow.removeEventListener('mousemove', mousemoveFN);
        this.windowRef.nativeWindow.removeEventListener('keydown', keyDownFn);
        this.menu?.close();
      };

      const mousemoveFN = (mouseMoveEvent: MouseEvent) => {

        const composedPath = mouseMoveEvent.composedPath();
        const onMenuElement = composedPath.includes(menuElement);
        const onHostElement = this.isMouseOnRect(mouseMoveEvent, bufferRect);
        const shouldBeClosed = !onHostElement && !onMenuElement;

        if (shouldBeClosed) {
          closeFn();
        }
      };

      const keyDownFn = (keydownEvent: KeyboardEvent) => {
        if (keydownEvent.key === 'Tab') {
          setTimeout(() => {
            const currentlyFocussedElement = this.windowRef.document.activeElement;
            const composedPath = getComposedPath(currentlyFocussedElement);
            const onMenuElement = composedPath.includes(menuElement);
            const onHostElement = composedPath.includes(hostElement);
            const shouldBeClosed = !onHostElement && !onMenuElement;
            // console.log('currently focussed Element', currentlyFocussedElement, 'Menu should be closed', shouldBeClosed);
            if (shouldBeClosed) {
              closeFn();
            }
          }, 0);
        }
        if (keydownEvent.key === 'Escape') {
          closeFn();
          keydownEvent.preventDefault();
        }
      };

      this.windowRef.nativeWindow.addEventListener('mousemove', mousemoveFN);
      this.windowRef.nativeWindow.addEventListener('keydown', keyDownFn);

    }
  };

  private isMouseOnRect(e: MouseEvent, rect: {x: number; y: number; width: number; height: number;}): boolean {
    const mx = e.clientX;
    const my = e.clientY;
    return (rect.x <= mx && (rect.x + rect.width) >= mx && rect.y <= my && (rect.y + rect.height) >= my);
  }

}
